import { apiEgpmTaskAdd } from "@/request/egpmApi";
import { computed } from 'vue';
import { ElMessage } from 'element-plus';
import withLoading from '@/utils/loading';
import { useRouter, useRoute } from 'vue-router';

export default ({ form, dicts, requestWeight }) => {

    const router = useRouter();
    const route = useRoute();
    const params = computed(() => {
        const res = {
            reportConfigId: route.query.reportId, // 报告id
            taskName: form.taskName, // 任务名称
            calc: form.calc, // 计算方式:1-菜品;2-母菜名称(必填)
            ratio: form.ratio, //	比率:1-同比;2-环比(必填)
            minPrice: form.minPrice,// 最小价格
            maxPrice: form.maxPrice,// 最大价格
            weightConfig: form.weightConfig, // 权重列表
        }
        res['minBatch'] = dicts.batch.data.find(item => item.id === form.minBatch);// 开始批次
        res['maxBatch'] = dicts.batch.data.find(item => item.id === form.maxBatch);// 结束批次
        res['province'] = dicts.province.data.filter(item => form.province.includes(item.id));// 省份
        res['city'] = dicts.city.data.filter(item => form.city.includes(item.id));// 城市
        res['cuisine1'] = dicts.cuisine1.data.filter(item => form.cuisine1.includes(item.id));// 餐饮类型
        res['cuisine2'] = dicts.cuisine2.data.filter(item => form.cuisine2.includes(item.id));// 菜系类型
        res['cuisine3'] = dicts.cuisine3.data.filter(item => form.cuisine3.includes(item.id));// 菜系
        res['material1'] = dicts.material1.data.filter(item => form.material1.includes(item.id));// 原料大类
        res['material2'] = dicts.material2.data.filter(item => form.material2.includes(item.id));// 原料二类
        res['material3'] = dicts.material3.data.filter(item => form.material3.includes(item.id));// 原料细类
        res['flavor1'] = dicts.flavor1.data.filter(item => form.flavor1.includes(item.id));// 风味大类
        res['flavor2'] = dicts.flavor2.data.filter(item => form.flavor2.includes(item.id));// 风味二级
        res['process1'] = dicts.process1.data.filter(item => form.process1.includes(item.id));// 制作工艺
        res['style1'] = dicts.style1.data.filter(item => form.style1.includes(item.id));// 奉客形式
        return res;
    })

    // 重置
    function resetForm(formEl) {
        if (!formEl) return;
        formEl.resetFields();
        requestWeight();// 重置权重设置
    }

    // 提交
    async function submitForm(formEl) {
        if (!formEl) return
        await formEl.validate((valid) => {
            if (!valid) return;
            // 批次区间验证
            if (params.value.minBatch.value > params.value.maxBatch.value) {
                ElMessage.error('开始批次不能大于结束批次');
                return;
            }
            // 客单价区间验证
            if (params.value.minPrice > params.value.maxPrice) {
                ElMessage.error('最小客单价不能大于最大客单价');
                return;
            }
            // 权重评分验证
            let weightTotal = 0;
            params.value.weightConfig.forEach(item => {
                weightTotal += Number(item.configValue);
            })
            if (weightTotal !== 100) {
                ElMessage.error('评分权重总计必须为100');
                return;
            }
            send(params.value)
        })
    }

    // 发送创建任务请求
    async function send(params) {
        const { code, data } = await withLoading(apiEgpmTaskAdd)(params);
        if (code !== '0') return;
        ElMessage.success('任务创建成功');
        goTaskDetail(data);
    }

    // 跳转任务详情
    function goTaskDetail(id) {
        router.replace({
            path: '/egpm/details',
            query: {
                reportId: route.query.reportId,
                id
            }
        })
    }

    return { resetForm, submitForm }
}