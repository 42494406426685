import useEgpmStore from "@/store/egpm";
import { h } from "vue";
import { useRoute } from 'vue-router';

export default (form) => {
    const route = useRoute();
    const egpmStore = useEgpmStore();

    // 获取默认权重配置
    async function requestWeight() {
        form.weightConfig = await egpmStore.getWeightConfig(route.query.reportId);
    }

    // 合计
    function getSummaries() {
        // 计算configValue的总和
        let total = 0;
        form.weightConfig.forEach((item) => {
            total += Number(item.configValue);
        });
        return ["", "总计", h('div', { style: { color: total > 100 ? 'red' : '' } }, total)];
    }

    // 合并
    function mergeRows({ row, column }) {
        if (column.label === "大类") {
            return { rowspan: row.rowspan, colspan: row.colspan };
        } else {
            return { rowspan: 1, colspan: 1 };
        }
    }

    requestWeight();

    return { getSummaries, mergeRows, requestWeight }
}